/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Subtitle, Text, FullmapCover, Image, Divider } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader />

        <Column className="css-10cxvnw js-anim  --anim7 --anim-s5 --full" anim={"7"} name={"intro"} animS={"5"} border={null} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/31348/02089ad5c7ed43f0bc09f5b6d63ff6ad_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/31348/02089ad5c7ed43f0bc09f5b6d63ff6ad_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/31348/02089ad5c7ed43f0bc09f5b6d63ff6ad_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/31348/02089ad5c7ed43f0bc09f5b6d63ff6ad_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/31348/02089ad5c7ed43f0bc09f5b6d63ff6ad_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/31348/02089ad5c7ed43f0bc09f5b6d63ff6ad_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/31348/02089ad5c7ed43f0bc09f5b6d63ff6ad_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/31348/02089ad5c7ed43f0bc09f5b6d63ff6ad_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 pt--10 flex--center" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim6 --anim-s5 pt--12" anim={"6"} animS={"5"} style={{"maxWidth":1080}}>
              
              <Title className="title-box fs--102 w--300 lh--12" content={"<span style=\"color: white;\">Dita &amp; Robert</span>"}>
              </Title>

              <Subtitle className="subtitle-box mt--10" content={"<span style=\"color: white\">06.07.2023.</span>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1 pt--10 flex--center" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim6 --anim-s5 pt--12" anim={"6"} animS={"5"} style={{"maxWidth":1080}}>
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--25 pt--50" name={"information"} style={{"backgroundColor":"#f7faf7"}} border={null} layout={"l13"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --center el--2 pl--0 pr--0 flex--center" anim={"2"} animS={"3"} style={{"backgroundColor":"rgba(247,250,247,1)"}} columns={"2"} fullscreen={false}>
            
            <ColumnWrapper style={{"maxWidth":510}}>
              
              <Title className="title-box title-box--style8 title-box--center fs--62 w--300" content={"<span style=\"color: rgb(235, 189, 12);\">Ceremony</span>"}>
              </Title>

              <Text className="text-box" content={"<span style=\"color: var(--color-blend--85); font-weight: bold;\">13:00</span><br>"}>
              </Text>

              <Text className="text-box" content={"<span style=\"color: var(--color-blend--85);\"><span style=\"font-weight: bold;\">Rooftop bar \"Herbārijs\" at Galleria Riga,<br>Dzirnavu street 67.</span><br></span>"}>
              </Text>

              <Text className="text-box fs--18" content={"<span style=\"color: var(--color-blend--85); font-style: italic;\">The ceremony will take place in one of the most beautiful rooftop bars in the heart of Riga.</span>"}>
              </Text>

              <Text className="text-box" content={"<span style=\"color: rgb(235, 189, 12);\"><span style=\"font-weight: bold;\">Please note:</span> </span><span style=\"color: var(--color-blend--85);\">our wedding days falls in the Song and Dance Festival time, so we all can expect some changes in the traffic. Please plan to arrive to the ceremony place at least 20-30 minutes before the starting time.</span><br>"}>
              </Text>

              <FullmapCover zoom={"13"} place={"herbārijs"}>
              </FullmapCover>

            </ColumnWrapper>

            <ColumnWrapper anim={null} animS={null}>
              
              <Image className="--shape2" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/31348/ecbfcf092cbc47ba9a60ad78bef12a67_e=6x0x1627x2440_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/31348/ecbfcf092cbc47ba9a60ad78bef12a67_e=6x0x1627x2440_s=350x_.jpg 350w, https://cdn.swbpg.com/t/31348/ecbfcf092cbc47ba9a60ad78bef12a67_e=6x0x1627x2440_s=660x_.jpg 660w, https://cdn.swbpg.com/t/31348/ecbfcf092cbc47ba9a60ad78bef12a67_e=6x0x1627x2440_s=860x_.jpg 860w, https://cdn.swbpg.com/t/31348/ecbfcf092cbc47ba9a60ad78bef12a67_e=6x0x1627x2440_s=1400x_.jpg 1400w"} position={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--25" name={"information-2"} style={{"backgroundColor":"#f7faf7"}} layout={"l13"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --center el--2 pl--0 pr--0 flex--center" anim={"2"} animS={"3"} style={{"backgroundColor":"rgba(247,250,247,1)"}} columns={"2"} fullscreen={false}>
            
            <ColumnWrapper anim={null} animS={null}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/31348/61e8195171774b45857a0a622b0293b4_e=5x13x1623x2436_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/31348/61e8195171774b45857a0a622b0293b4_e=5x13x1623x2436_s=350x_.jpg 350w, https://cdn.swbpg.com/t/31348/61e8195171774b45857a0a622b0293b4_e=5x13x1623x2436_s=660x_.jpg 660w, https://cdn.swbpg.com/t/31348/61e8195171774b45857a0a622b0293b4_e=5x13x1623x2436_s=860x_.jpg 860w, https://cdn.swbpg.com/t/31348/61e8195171774b45857a0a622b0293b4_e=5x13x1623x2436_s=1400x_.jpg 1400w"} position={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":510}}>
              
              <Title className="title-box title-box--style8 title-box--center fs--62 w--300" style={{"paddingLeft":1}} content={"<span style=\"color: rgb(213, 122, 155);\">Reception</span>"}>
              </Title>

              <Text className="text-box" content={"<span style=\"font-weight: bold; color: var(--color-blend--85);\">Guests arriving starting 16:30</span><br>"}>
              </Text>

              <Text className="text-box" content={"<span style=\"color: var(--color-blend--85); font-weight: bold;\">Hotel \"Rožmalas\",<br>Nameju street 2, Ceraukste</span><br>"}>
              </Text>

              <Text className="text-box fs--18" content={"<span style=\"font-style: italic;\"><span style=\"color: var(--color-blend--85);\">Rožmalas is a spacious leisure centre in the heart of Zemgale with a majestic windmill located right in&nbsp;</span><span style=\"caret-color: rgb(73, 81, 85); color: rgb(73, 81, 85);\">its backyard.</span></span><br>"}>
              </Text>

              <Text className="text-box" content={"<span style=\"font-weight: bold; color: rgb(213, 122, 155);\">Please note:</span><span style=\"color: var(--color-blend--15);\"> </span><span style=\"color: var(--color-blend--85);\">If you use Waze for navigation, please turn left off from A7 highway before Rožmalas building. Unfortunately, Waze has set wrong turn, which then will lead you to the backyard. <br><span style=\"font-weight: bold;\">Parking is in front on the building.</span> So - once you see Rožmalas building, turn left off from highway, drive until a small roundabout where the parking is.&nbsp;</span>"}>
              </Text>

              <FullmapCover zoom={"14"} place={"Rožmalas"}>
              </FullmapCover>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"mqa6hgqrs1h"} style={{"backgroundColor":"#f7faf7"}} layout={"l13"}>
          
          <ColumnWrap className="column__flex --left el--2 flex--center" style={{"paddingTop":0,"backgroundColor":"rgba(247,250,247,1)"}} columns={"2"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":480}}>
              
              <Title className="title-box title-box--center fs--48" style={{"maxWidth":598}} content={"<span style=\"color: rgb(235, 189, 12);\">Have you already filled in the survey?</span><br>"}>
              </Title>

              <Text className="text-box text-box--center" content={"<span style=\"color: var(--color-blend--85);\">For a smooth wedding preparation, we kindly ask you to fill in the survey! Click on the link below!&nbsp;</span>"}>
              </Text>

              <Text className="text-box text-box--center" content={"<span style=\"font-weight: bold; color: rgb(235, 189, 12);\"><a href=\"https://docs.google.com/forms/d/e/1FAIpQLSdjNL3QdBJPIVgV9rVfMODITqIFDC0jr_nPUbZZvRV24K9FKw/viewform\" target=\"_blank\" style=\"\">ej.uz/boswedding</a></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/31348/81e08db7d1f4498e805cdfffefd6fa05_e=0x0x1633x2449_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/31348/81e08db7d1f4498e805cdfffefd6fa05_e=0x0x1633x2449_s=350x_.jpg 350w, https://cdn.swbpg.com/t/31348/81e08db7d1f4498e805cdfffefd6fa05_e=0x0x1633x2449_s=660x_.jpg 660w, https://cdn.swbpg.com/t/31348/81e08db7d1f4498e805cdfffefd6fa05_e=0x0x1633x2449_s=860x_.jpg 860w, https://cdn.swbpg.com/t/31348/81e08db7d1f4498e805cdfffefd6fa05_e=0x0x1633x2449_s=1400x_.jpg 1400w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"sq1vkqe17bs"} style={{"backgroundColor":"#f7faf7"}}>
          
          <ColumnWrap className="column__flex --center el--1 mb--20 flex--top" style={{"maxWidth":""}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--style4" content={"<span style=\"color: rgb(213, 122, 155);\">Plan of the day</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="mt--0 pt--0">
              
              <Text className="text-box" style={{"maxWidth":580}} content={"<span style=\"color: rgb(213, 122, 155); font-weight: bold;\">12:30</span><span style=\"color: var(--color-blend--85);\"> - Guests arriving at \"Herbārijs\"</span><br><span style=\"color: rgb(213, 122, 155); font-weight: bold;\">13:00</span><span style=\"color: var(--color-blend--85);\"> - Ceremony</span><br><span style=\"color: rgb(213, 122, 155); font-weight: bold;\">14:00</span><span style=\"color: var(--color-blend--85);\"> - Cakes, snacks, flowers and drinks after the ceremony</span><br><span style=\"font-weight: bold; color: rgb(213, 122, 155);\">15:30</span><span style=\"color: var(--color-blend--85);\"> - Guests leaving to \"Rožmalas\"</span><br><span style=\"color: rgb(213, 122, 155); font-weight: bold;\">16:30</span><span style=\"color: var(--color-blend--85);\"> - Guests arriving at \"Rožmalas\"</span><br><span style=\"font-weight: bold; color: rgb(213, 122, 155);\">17:00-18:30 </span><span style=\"color: var(--color-blend--85);\">- Moving into the rooms, activities, welcome drinks and snacks</span><br><span style=\"color: rgb(213, 122, 155); font-weight: bold;\">19:00</span><span style=\"color: var(--color-blend--85);\"> - Wedding dinner</span><br><span style=\"font-weight: bold; color: rgb(213, 122, 155);\">20:30 </span><span style=\"color: var(--color-blend--85);\">- First dance</span><br><span style=\"font-weight: bold; color: rgb(213, 122, 155);\">22:00</span><span style=\"color: var(--color-blend--85);\"> - Latvian wedding ritual</span><br><span style=\"font-weight: bold; color: rgb(213, 122, 155);\">23:00</span><span style=\"color: var(--color-blend--85);\"> - Party until the morning!</span><br>"}>
              </Text>

              <Divider >
              </Divider>

              <Text className="text-box" content={"<span style=\"font-weight: bold; color: rgb(213, 122, 155);\">SECOND DAY</span><br><span style=\"color: rgb(213, 122, 155); font-weight: bold;\">10:00</span><span style=\"color: var(--color-blend--85);\"> - Breakfast</span><br><span style=\"color: rgb(213, 122, 155); font-weight: bold;\">12:00</span><span style=\"color: var(--color-blend--85);\"> - Check-out of the rooms</span><br><span style=\"color: rgb(213, 122, 155); font-weight: bold;\">12:00-16:00</span><span style=\"color: var(--color-blend--85);\"> Second day chill&nbsp;</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2 pt--30 flex--top" style={{"maxWidth":""}} columns={"2"}>
            
            <ColumnWrapper className="--hvr5 --left">
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/31348/13dcf74a05a14016b1b2144d940d9bbe_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/31348/13dcf74a05a14016b1b2144d940d9bbe_s=350x_.jpg 350w, https://cdn.swbpg.com/t/31348/13dcf74a05a14016b1b2144d940d9bbe_s=660x_.jpg 660w, https://cdn.swbpg.com/t/31348/13dcf74a05a14016b1b2144d940d9bbe_s=860x_.jpg 860w, https://cdn.swbpg.com/t/31348/13dcf74a05a14016b1b2144d940d9bbe_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--hvr5 --left">
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/31348/403a74fa9fa94d4581d347491b624d4e_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/31348/403a74fa9fa94d4581d347491b624d4e_s=350x_.jpg 350w, https://cdn.swbpg.com/t/31348/403a74fa9fa94d4581d347491b624d4e_s=660x_.jpg 660w, https://cdn.swbpg.com/t/31348/403a74fa9fa94d4581d347491b624d4e_s=860x_.jpg 860w, https://cdn.swbpg.com/t/31348/403a74fa9fa94d4581d347491b624d4e_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1 mt--50 flex--top" style={{"maxWidth":""}} columns={"1"}>
          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"9mjperp8j47"} style={{"backgroundColor":"#f7faf7"}}>
          
          <ColumnWrap className="column__flex --left el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--style2 title-box--center" content={"<span style=\"color: rgb(235, 189, 12);\">Dress code</span><br>"}>
              </Title>

              <Text className="text-box text-box--center" style={{"maxWidth":467}} content={"<span style=\"color: var(--color-blend--85);\">Please choose formal attire for our wedding day. As one of the main colours of the wedding is mustard yellow, we would appreciate if you would avoid any shade of yellow colour in your attire to avoid chaotic look of the day. </span>"}>
              </Text>

              <Text className="text-box text-box--center" content={"<span style=\"font-weight: bold; color: rgb(235, 189, 12);\">Pastel colours are more than welcome!</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--style2 title-box--center" content={"<span style=\"color: rgb(235, 189, 12);\">Gifts</span><br>"}>
              </Title>

              <Text className="text-box text-box--center" style={{"maxWidth":467}} content={"<span style=\"color: var(--color-blend--85);\">We will gratefully accept envelopes with contribution towards our honeymoon. We would also appreciate bottle of a nice drink. Robert really enjoys whiskey or wine, while Dita loves champagne.</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--style2 title-box--center fs--62" content={"<span style=\"caret-color: rgb(237, 188, 204); color: rgb(235, 189, 12);\">Contacts</span><br>"}>
              </Title>

              <Text className="text-box text-box--center" style={{"maxWidth":467}} content={"<span style=\"font-weight: bold; color: rgb(235, 189, 12);\">If at any moment you have any questions or&nbsp;</span><span style=\"caret-color: rgb(223, 224, 225);\"><span style=\"font-weight: bold; color: rgb(235, 189, 12);\">concerns, please let us know!</span><br></span><span style=\"color: var(--color-blend--85);\">Dita: +37129162190, Robert: +37127403605<br>Wedding planner Kristīne: +371 27601226<br>Maid of honour Dita: +371 26004484<br>Best man Gillian: +371 26124880.</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--80 pt--80" name={"kfygngrev8g"} style={{"backgroundColor":"#f7faf7"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center fs--36" content={"<span style=\"font-weight: bold; color: rgb(213, 122, 155);\">We know that you might have some questions. <br>Keep reading and maybe you will find the answers you are looking for!&nbsp;</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"ojv3ez8xl2d"} style={{"backgroundColor":"#f7faf7"}}>
          
          <ColumnWrap className="column__flex --center el--3 pb--30 pt--30" columns={"3"}>
            
            <ColumnWrapper >
              
              <Text className="text-box" content={"<span style=\"font-weight: bold; color: rgb(235, 189, 12);\">What are the parking options nearby \"Herbārijs\"?</span><span style=\"color: rgb(235, 189, 12); font-weight: bold;\"><br></span>"}>
              </Text>

              <Text className="text-box fs--18" style={{"maxWidth":335}} content={"<span style=\"color: var(--color-blend--85);\">There are several parking options nearby. You can try and find a parking spot <span style=\"font-weight: bold;\">on the side of the street</span>, however, it is usually very busy. There is also a <span style=\"font-weight: bold;\">parking lot across the street</span> or <span style=\"font-weight: bold;\">underground parking under Galleria Riga</span>. Please note that all parking options are paid parking, which is approximately 2-3EUR/h.</span>"}>
              </Text>

              <Image alt={""} src={"https://cdn.swbpg.com/t/31348/a0a165f6ecd245798291fe484e3428d3_s=860x_.png"} sizes={"100vw"} style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/31348/a0a165f6ecd245798291fe484e3428d3_s=350x_.png 350w, https://cdn.swbpg.com/t/31348/a0a165f6ecd245798291fe484e3428d3_s=660x_.png 660w, https://cdn.swbpg.com/t/31348/a0a165f6ecd245798291fe484e3428d3_s=860x_.png 860w, https://cdn.swbpg.com/t/31348/a0a165f6ecd245798291fe484e3428d3_s=1400x_.png 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box" content={"<span style=\"color: rgb(235, 189, 12); font-weight: bold;\">Will there be transportation arranged?</span>"}>
              </Text>

              <Text className="text-box fs--18" style={{"maxWidth":335}} content={"<span style=\"color: var(--color-blend--85);\">Yes! We have arranged the bus that will take you to \"Rožmalas\" after the ceremony and will bring you back to Riga on the second day of the wedding.&nbsp;</span><br><span style=\"color: var(--color-blend--85);\">The bus will be parked downstairs of Galleria Riga already at 12:00, and you are more than welcome to leave your&nbsp;</span><span style=\"caret-color: rgb(73, 81, 85); color: rgb(73, 81, 85);\">luggage in it, so you don't have to carry all&nbsp;your belongings to the ceremony. On the second day the bus will leave from \"Rožmalas\" at 16:00. If you wish y</span><span style=\"color: var(--color-blend--85);\">ou are also more than welcome to drive your own car or do car pooling with other guests!</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box" content={"<span style=\"color: rgb(235, 189, 12); font-weight: bold;\">What about sleeping arrangements?</span>"}>
              </Text>

              <Text className="text-box fs--18" style={{"maxWidth":335}} content={"<span style=\"color: var(--color-blend--85);\"><span style=\"caret-color: rgb(73, 81, 85);\">\"Rožmalas\" offer nice and comfy rooms to all our guests. Rooms have their own private bathroom and toilet. Sleeping will be arranged in the rooms of 2-4 guests per each room. </span>You will get the key to your room and will be arranged to a room upon your arrival in \"Rožmalas\".&nbsp;</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="mt--50">
              
              <Text className="text-box" content={"<span style=\"color: rgb(235, 189, 12); font-weight: bold;\">Can I bring my spouse/partner with me?</span>"}>
              </Text>

              <Text className="text-box fs--18" style={{"maxWidth":335}} content={"<span style=\"color: var(--color-blend--85);\">Both \"Herbārijs\" and \"Rožmalas\" have a limited space. Therefore, we kindly ask you not to bring a plus one, unless they are specifically named on the invitation.&nbsp;</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="mt--50">
              
              <Text className="text-box" content={"<span style=\"color: rgb(235, 189, 12); font-weight: bold;\">Can&nbsp;</span><span style=\"caret-color: rgb(235, 189, 12); color: rgb(235, 189, 12); font-weight: bold;\">I bring my kids along to the wedding?</span>"}>
              </Text>

              <Text className="text-box fs--18" style={{"maxWidth":335}} content={"<span style=\"color: var(--color-blend--85);\">To give all our guests the opportunity to enjoy the day at its fullest, we are keeping our special day as adults-only.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="mt--50">
              
              <Text className="text-box" content={"<span style=\"color: rgb(235, 189, 12); font-weight: bold;\">For how long will the party take place?&nbsp;</span><br>"}>
              </Text>

              <Text className="text-box fs--18" style={{"maxWidth":335}} content={"<span style=\"color: var(--color-blend--85);\">We have booked \"Rožmalas\" up until July 7, 16:00. All guests should leave rooms at noon on July 7, however, we will keep celebrating in Rožmalas until 16:00. On the second day sauna and hot baths will be available, so we invite you to pack your swimwear with you!</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"em1pvi73v79"} style={{"backgroundColor":"#f7faf7"}}>
          
          <ColumnWrap className="column__flex --center el--2 pb--30 pt--30" columns={"2"}>
            
            <ColumnWrapper >
              
              <Text className="text-box" content={"<span style=\"font-weight: bold; color: rgb(235, 189, 12);\">Do I need to bring my own bedsheets and towels?</span>"}>
              </Text>

              <Text className="text-box fs--18" style={{"maxWidth":335}} content={"<span style=\"color: var(--color-blend--85);\">\"Rožmalas\" offer everything for a pleasant stay, however, please pack a towel if you plan to enjoy sauna and hot baths on the second day.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box" content={"<span style=\"color: rgb(235, 189, 12); font-weight: bold;\">Is there something I can help you out with?</span>"}>
              </Text>

              <Text className="text-box fs--18" style={{"maxWidth":335}} content={"<span style=\"color: var(--color-blend--85);\">Yes! Make sure you have filled in the survey here: </span><span style=\"font-weight: bold; color: rgb(235, 189, 12);\"><a href=\"https://docs.google.com/forms/d/e/1FAIpQLSdjNL3QdBJPIVgV9rVfMODITqIFDC0jr_nPUbZZvRV24K9FKw/viewform\" target=\"_blank\">ej.uz/boswedding</a></span><span style=\"color: var(--color-blend--85);\">. If at any moment your plans change, please let us know so we can adjust accordingly. Other than that, prepare for a great party, bring your&nbsp;</span><span style=\"caret-color: rgb(73, 81, 85); color: rgb(73, 81, 85);\">dancing</span><span style=\"color: var(--color-blend--85);\">&nbsp;shoes and the nice mood!<a href=\"https://docs.google.com/forms/d/e/1FAIpQLSdjNL3QdBJPIVgV9rVfMODITqIFDC0jr_nPUbZZvRV24K9FKw/viewform\" target=\"_blank\"> :)</a></span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-rbd93u --center pb--80 pt--80" name={"information-5"} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/31348/9af1373e889845c39a131228af880389_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/31348/9af1373e889845c39a131228af880389_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/31348/9af1373e889845c39a131228af880389_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/31348/9af1373e889845c39a131228af880389_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/31348/9af1373e889845c39a131228af880389_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/31348/9af1373e889845c39a131228af880389_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/31348/9af1373e889845c39a131228af880389_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/31348/9af1373e889845c39a131228af880389_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":1080}} columns={"1"}>
            
            <ColumnWrapper className="--left mt--80">
              
              <Title className="title-box title-box--center fs--72" content={"<span style=\"color: white\">We look forward to celebrating our love together with you!</span>"}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--style8 subtitle-box--center" content={"<span style=\"color: white\">06.07.2023</span>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1 mb--80 mt--40" style={{"maxWidth":900}} columns={"1"}>
            
            <Text className="text-box text-box--style8 text-box--center" content={"<span style=\"color: white\"><br></span>"}>
            </Text>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}